<template>
  <div class="space-y-4">
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <asom-card>
      <form class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
        <asom-form-field
          label="MRT Station"
          required
          :state="inputStates('formData.stationId')"
          error="MRT Station is required"
        >
          <asom-input-select
            :objectModelValue="false"
            v-model="formData.stationId"
            :options="stationOptions"
            placeholder="Select MRT station"
            :state="inputStates('formData.stationId')"
          />
        </asom-form-field>
        <div></div>
        <asom-form-field
          label="Landmark Map"
          class="col-span-2"
          required
          :state="inputStates('formData.attachment')"
          error="Please upload a PDF file for landmark map"
        >
          <div class="space-y-4">
            <template v-if="formData.stationId">
              <div class="space-x-2 flex content-center" v-if="isLoadingMap">
                <asom-icon icon="spinner" class="animate-spin" />
                <span>Loading current landmark map...</span>
              </div>
              <asom-alert
                v-else-if="!currentTouristMap"
                variant="info"
                title="No landmark map found for this station"
              />
              <span v-else
                ><span class="font-semibold pr-2">Current Map File</span
                >{{ get(currentTouristMap, "attachmentFileName") }}</span
              >
            </template>
            <asom-input-text
              type="file"
              ref="fileInput"
              @change="onFileUpload"
              accept="application/pdf"
              :state="inputStates('formData.attachment')"
            />
          </div>
        </asom-form-field>
        <asom-form-field
          v-if="currentTouristMap"
          label="Landmarks"
          class="col-span-2"
          description="Please review and edit imported data for landmarks"
        >
          <div class="space-y-2">
            <asom-button
              size="sm"
              variant="success"
              text="New Landmark"
              @click="createLandMark"
            />
            <asom-client-table
              :columns="['no', 'landMark', 'action']"
              :data="landMarkTableData"
              :pagination="false"
              :filterable="false"
            >
              <template v-slot:no="slotScoped">
                {{ slotScoped.rowIndex + 1 }}
              </template>
              <template v-slot:action="slotScoped">
                <div class="flex flex-col gap-1 w-48">
                  <asom-button
                    size="sm"
                    text="Edit"
                    @click="
                      editLandMark(
                        slotScoped.rowIndex,
                        slotScoped.rowData.landMark
                      )
                    "
                  />
                  <asom-button
                    size="sm"
                    variant="error"
                    text="Remove"
                    @click="confirmRemoval(slotScoped.rowIndex)"
                  />
                </div>
              </template>
            </asom-client-table>
          </div>
        </asom-form-field>
        <div class="col-span-2 flex justify-end space-x-2">
          <asom-button
            text="Cancel"
            variant="secondary"
            @click="$router.push({ name: 'Wayfinding Main Page' })"
          />
          <asom-button
            text="Update Configuration"
            :disabled="isSubmitting || invalidForm"
            :is-loading="isSubmitting"
            @click="submit"
          />
        </div>
      </form>
    </asom-card>
    <asom-modal
      v-model="showRemovalConfirmation"
      title="Confirmation"
      :dismissible="false"
    >
      <p class="pt-4">Are you sure you would like to remove this landmark?</p>
      <div class="flex flex-row-reverse pt-4 gap-4">
        <asom-button @click="removeLandMark" variant="error" text="Confirm" />
        <asom-button
          @click="closeRemovalConfirmationModal"
          text="Cancel"
          variant="secondary"
        />
      </div>
    </asom-modal>
    <asom-modal
      v-model="showLandmarkEdit"
      title="Edit Landmark"
      :dismissible="false"
    >
      <asom-form-field label="Landmark">
        <asom-input-textarea v-model="editLandMarkFormData.landMark" />
      </asom-form-field>
      <div class="flex flex-row-reverse pt-4 gap-4">
        <asom-button @click="confirmEdit" text="Confirm" />
        <asom-button
          @click="closeEditLandMarkModal"
          text="Cancel"
          variant="secondary"
        />
      </div>
    </asom-modal>
    <asom-modal
      v-model="showLandmarkCreate"
      title="New Landmark"
      :dismissible="false"
    >
      <asom-form-field label="Landmark">
        <asom-input-textarea v-model="newLandMarkFormData.landMark" />
      </asom-form-field>
      <div class="flex flex-row-reverse pt-4 gap-4">
        <asom-button @click="confirmCreate" text="Confirm" />
        <asom-button
          @click="closeCreateLandMarkModal"
          text="Cancel"
          variant="secondary"
        />
      </div>
    </asom-modal>
  </div>
</template>
<script>
import get from "lodash.get";
import isEmpty from "lodash.isempty";
import { mapGetters } from "vuex";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import inputStates from "@/mixins/inputStates";
import {
  getMapDetails,
  getLandMarks,
  createMap,
} from "@/services/tourist.service";
import { uploadFile } from "../../services/file.service";
import { ATTACHMENT_CATEGORIES } from "../../constants/APIEnums/attachmentEnums";

export default {
  mixins: [inputStates],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      error: null,
      isSubmitting: false,
      mapError: null,
      isLoadingMap: false,
      formData: {
        stationId: null,
        attachment: null,
        landMarks: [],
        attachmentId: null,
      },
      newLandMarkFormData: {
        landMark: null,
      },
      editLandMarkFormData: {
        index: null,
        landMark: null,
      },
      currentTouristMap: null,
      selectedIndexForRemoval: null,
      showRemovalConfirmation: false,
      showLandmarkEdit: false,
      showLandmarkCreate: false,
    };
  },
  validations() {
    return {
      formData: {
        stationId: { required },
        attachment: { required },
      },
    };
  },
  computed: {
    ...mapGetters({
      stationOptions: "smrtResource/wayfindingStationOptions",
    }),
    landMarkTableData() {
      return this.formData.landMarks.map((l) => ({
        landMark: l,
      }));
    },
    invalidForm() {
      return !this.formData.attachment;
    }
  },
  watch: {
    "formData.stationId": function(newValue) {
      if (newValue !== null) {
        this.loadMapDetails(newValue);
      }
    },
    error: function(newValue) {
      if (newValue !== null) this.$scrollTop();
    },
  },
  methods: {
    get,
    onFileUpload(event) {
      this.formData.attachmentId = null;
      this.formData.attachment = event.target.files[0];
      this.getLandMarkData();
    },
    async loadMapDetails(stationId) {
      this.mapError = null;
      this.isLoadingMap = true;
      const resp = await getMapDetails(stationId);
      if (resp.success) {
        this.currentTouristMap = get(resp, "payload.data");
        this.formData.attachmentId = get(resp, "payload.data.attachmentId");
        this.formData.attachment = "landmark_file";
        const landMarks = get(resp, "payload.data.landMarkData");
        if (!isEmpty(landMarks)) {
          this.formData.landMarks = landMarks.split(";");
        }
      } else {
        this.mapError = resp.payload;
      }
      this.isLoadingMap = false;
    },
    async getLandMarkData() {
      this.isSubmitting = true;
      const resp1 = await getLandMarks([this.formData.attachment]);
      if (!resp1.success) {
        this.error = resp1.payload;
        this.isSubmitting = false;
        return;
      }
      this.formData.landMarks = get(resp1, "payload.landMarks", []);
      this.isSubmitting = false;
    },
    async submit() {
      if (this.isSubmitting) return;
      this.error = null;
      this.v$.$reset();
      this.v$.$touch();
      if (this.v$.formData.$invalid) {
        this.error = "Please complete all required fields";
        return;
      }

      this.isSubmitting = true;
      this.error = null;

      if (!this.formData.attachmentId) {
        const resp0 = await uploadFile([this.formData.attachment], ATTACHMENT_CATEGORIES.TOURIST);
        if (!resp0.success) {
          this.error = resp0.payload;
          this.isSubmitting = false;
          return;
        }
        this.formData.attachmentId = get(resp0, "payload.files.0.fileId");
      }

      const resp2 = await createMap({
        stationId: this.formData.stationId,
        attachmentId: this.formData.attachmentId,
        landMarks: this.formData.landMarks,
      });
      if (!resp2.success) {
        this.error = resp2.payload;
      } else {
        this.$router.push({ name: "Wayfinding Main Page" });
      }
      this.isSubmitting = false;
    },
    confirmRemoval(index) {
      this.selectedIndexForRemoval = index;
      this.showRemovalConfirmation = true;
    },
    closeRemovalConfirmationModal() {
      this.selectedIndexForRemoval = null;
      this.showRemovalConfirmation = false;
    },
    removeLandMark() {
      this.formData.landMarks.splice(this.selectedIndexForRemoval, 1);
      this.closeRemovalConfirmationModal();
    },
    editLandMark(index, landMark) {
      this.editLandMarkFormData.index = index;
      this.editLandMarkFormData.landMark = landMark;
      this.showLandmarkEdit = true;
    },
    closeEditLandMarkModal() {
      this.showLandmarkEdit = false;
      this.editLandMarkFormData.index = null;
      this.editLandMarkFormData.landMark = null;
    },
    confirmEdit() {
      if (isEmpty(this.editLandMarkFormData.landMark)) return;

      if (this.formData.landMarks[this.editLandMarkFormData.index]) {
        this.formData.landMarks[
          this.editLandMarkFormData.index
        ] = this.editLandMarkFormData.landMark;
      }
      this.closeEditLandMarkModal();
    },
    createLandMark() {
      this.showLandmarkCreate = true;
    },
    closeCreateLandMarkModal() {
      this.showLandmarkCreate = false;
      this.newLandMarkFormData.landMark = null;
    },
    confirmCreate() {
      this.formData.landMarks = [
        ...this.formData.landMarks,
        this.newLandMarkFormData.landMark,
      ];
      this.closeCreateLandMarkModal();
    },
  },
};
</script>
